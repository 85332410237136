import {
  registerDashboardWidget,
  setDashboardWidgetLayout,
} from '@vendure/admin-ui/core';

export default [
  registerDashboardWidget('orderOrigins', {
    title: 'Order Origins',
    supportedWidths: [6],
    loadComponent: async () =>
      (
        await import(
          './components/order-origin-widget/order-origin-widget.component'
        )
      ).OrderOriginWidgetComponent,
  }),
  setDashboardWidgetLayout([
    { id: 'metrics', width: 12 },
    { id: 'orderSummary', width: 6 },
    { id: 'orderOrigins', width: 6 },
    { id: 'latestOrders', width: 12 },
  ]),
];
