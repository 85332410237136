import { gql } from 'apollo-angular';

export const GET_FULFILMENT_THEMES = gql`
  query ThemeSearchBySku($fulfilmentSku: String!) {
    themeSearchBySku(input: { fulfilmentSku: $fulfilmentSku }) {
      name
      themeRemoteId
      themeSetRemoteId
      config
    }
  }
`;

export const REMOVE_BRAND_LOGO_MUTATION = gql`
  mutation RemoveBrandLogoMutation($promotionId: ID!) {
    removeBrandLogo(promotionId: $promotionId) {
      id
    }
  }
`;

export const SAVE_BRAND_LOGO_MUTATION = gql`
  mutation SaveBrandLogoMutation($promotionId: ID!, $file: Upload!) {
    saveBrandLogo(input: { promotionId: $promotionId, file: $file }) {
      id
      customFields {
        brandLogo {
          id
        }
      }
    }
  }
`;

export const GET_COUPON_LIST = gql`
  query GetCouponList($options: CouponListOptions) {
    coupons(options: $options) {
      items {
        id
        code
        isGenericCode
        usageLimit
        usageCount
        createdAt
        updatedAt
        startsAt
        promotion {
          id
          name
        }
        enabled
      }
      totalItems
    }
  }
`;

export const GET_COUPON = gql`
  query GetCoupon($id: ID!) {
    coupon(id: $id) {
      id
      code
      usageLimit
      usageCount
      promotion {
        id
        name
      }
      createdAt
      updatedAt
      enabled
      startsAt
    }
  }
`;

export const GET_PROMOTION_SELECTOR_LIST = gql`
  query GetPromotionSelectorList($options: PromotionListOptions) {
    promotions(options: $options) {
      items {
        id
        name
      }
    }
  }
`;

export const CREATE_GENERIC_COUPON_MUTATION = gql`
  mutation createGenericCoupon(
    $code: String!
    $usageLimit: Int
    $promotionId: ID!
    $automate: Boolean!
    $startsAt: DateTime
  ) {
    createGenericCoupon(
      input: {
        code: $code
        usageLimit: $usageLimit
        promotionId: $promotionId
        automate: $automate
        startsAt: $startsAt
      }
    ) {
      __typename
      ... on Coupon {
        id
      }
      ... on CreateCouponAutomationResult {
        markedAsAutomation
      }
    }
  }
`;

export const UPDATE_GENERIC_COUPON_MUTATION = gql`
  mutation updateGenericCoupon(
    $id: ID!
    $usageLimit: Int
    $enabled: Boolean!
    $startsAt: DateTime
  ) {
    updateGenericCoupon(
      input: {
        id: $id
        usageLimit: $usageLimit
        enabled: $enabled
        startsAt: $startsAt
      }
    ) {
      __typename
      id
    }
  }
`;

export const UPDATE_UNIQUE_COUPON_MUTATION = gql`
  mutation updateUniqueCoupon(
    $id: ID!
    $enabled: Boolean!
    $startsAt: DateTime
  ) {
    updateUniqueCoupon(
      input: { id: $id, enabled: $enabled, startsAt: $startsAt }
    ) {
      id
    }
  }
`;

export const CREATE_UNIQUE_COUPONS_MUTATION = gql`
  mutation createUniqueCoupons(
    $amount: Int!
    $prefix: String!
    $promotionId: ID!
    $automate: Boolean!
    $startsAt: DateTime
  ) {
    createUniqueCoupons(
      input: {
        amount: $amount
        prefix: $prefix
        promotionId: $promotionId
        automate: $automate
        startsAt: $startsAt
      }
    ) {
      __typename
      ... on Coupon {
        id
      }
      ... on CreateMultipleCouponsEmailResult {
        addedToQueue
      }
      ... on CreateCouponAutomationResult {
        markedAsAutomation
      }
    }
  }
`;
