import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { type FormInputComponent } from '@vendure/admin-ui/core';
import { type Observable, tap } from 'rxjs';
import type { FormControl, FormGroup } from '@angular/forms';
import { type StringCustomFieldConfig } from '@vendure/core';
import { DataService } from '@vendure/admin-ui/core';
import type {
  FulfilmentTheme,
  ProductVariantCustomFields,
} from '../../generated-types/graphql';
import { ThemeSearchBySkuDocument } from '../../generated-types/graphql';
import { utils } from '@stampix/product-ui-shared';

/**
 * @description
 * A form control for selecting Fulfilment themes.
 *
 * @docsCategory components
 */
@Component({
  selector: 'theme-selector',
  templateUrl: './theme-selector.component.html',
  styleUrls: ['./theme-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemeSelectorComponent
  implements FormInputComponent<StringCustomFieldConfig>
{
  formControl!: FormControl<string | null>;
  config!: StringCustomFieldConfig;

  @Input() readonly = false;
  value!: string | null;

  themes$!: Observable<FulfilmentTheme[]>;
  isLoading = true;

  constructor(private dataService: DataService) {}

  ngOnInit() {
    const parent = this.formControl.parent;
    const variantSku = (parent?.parent?.value as { sku: string })?.sku;
    const fulfilmentSkuCustomField = (
      parent?.value as ProductVariantCustomFields
    )?.fulfilmentSku;

    const sku = fulfilmentSkuCustomField || variantSku;
    this.value = this.formControl.value;

    this.themes$ = this.dataService
      .query(ThemeSearchBySkuDocument, { fulfilmentSku: sku }, 'no-cache')
      .mapSingle((result) => result.themeSearchBySku)
      .pipe(tap(() => (this.isLoading = false)));
  }

  onChange(selected: FulfilmentTheme | null) {
    if (this.readonly) {
      return;
    }

    this.formControl.setValue(selected?.themeSetRemoteId ?? null);
    const customFieldsParent = this.formControl.parent as FormGroup;

    customFieldsParent.patchValue(
      {
        ...customFieldsParent.value,
        // We still want the nulls to be stringified.
        productConfig: JSON.stringify(selected ? selected.config : null),
        productSpecs: JSON.stringify(
          selected
            ? utils.getSimplifiedProductSpecifications(selected.config)
            : null,
        ),
      },
      { emitEvent: true },
    );

    this.formControl.markAsDirty();
  }
}
