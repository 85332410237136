<ng-select
  [items]="themes$ | async"
  [addTag]="false"
  appendTo="body"
  bindLabel="name"
  bindValue="themeSetRemoteId"
  [disabled]="readonly"
  [ngModel]="value"
  (change)="onChange($event)"
  width="100%"
  [loading]="isLoading"
  loadingText="Loading..."
>
</ng-select>
